<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import { fly, slide } from 'svelte/transition';

	let {
		buttonType = 'anchor',
		color = 'yellow',
		icon = null,
		loading = null,
		disabled = null,
		children,
		class: classNames = '',
		onclick = null,
		href = null,
		active = true,
		...props
	} = $props();

	// Determine which kind of element to render
	const Element = buttonType === 'anchor' ? 'a' : 'button';
</script>

{#snippet spinner()}
	<div
		transition:fly|local
		class="spinner absolute right-0 grid h-full place-items-center"
	>
		<svg
			class="stroke-indow-white m-2 w-6"
			stroke="#000"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			><g class="spinner"
				><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3" /></g
			></svg
		>
	</div>
{/snippet}

{#if active}
	<div class="button relative grid w-full place-items-center">
		{#if loading}
			{@render spinner()}
		{/if}
		<svelte:element
			this={Element}
			{onclick}
			{href}
			{...props}
			{disabled}
			class={[
				` wrap text-indow-white rounded-sm px-6 py-4 text-center text-base leading-none font-bold tracking-widest hyphens-none uppercase no-underline hover:no-underline focus:no-underline disabled:cursor-not-allowed`,
				{
					'grid grid-cols-[1rem,1fr] gap-4': icon,
					'bg-indow-yellow !text-indow-white': color == 'yellow',
					'bg-indow-blue !text-indow-white': color == 'blue',
					'bg-indow-green !text-indow-white': color == 'green',
					'bg-indow-grey-dark !text-indow-white': color == 'grey',
					'bg-indow-white !text-indow-blue border-2': color == 'white',
				},
				classNames,
			]}
			transition:slide|local={{ axis: 'x' }}
		>
			{#if icon}
				<Image descriptionSlot="" src={icon} />
			{/if}
			{@render children?.()}
		</svelte:element>
	</div>
{/if}

<style>
	.spinner {
		transform-origin: center;
		animation: spinner_rotate 2s linear infinite;
	}
	.spinner circle {
		stroke-linecap: round;
		animation: spinner_offset 1.5s ease-in-out infinite;
	}
	@keyframes spinner_rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes spinner_offset {
		0% {
			stroke-dasharray: 0 150;
			stroke-dashoffset: 0;
		}
		47.5% {
			stroke-dasharray: 42 150;
			stroke-dashoffset: -16;
		}
		95%,
		100% {
			stroke-dasharray: 42 150;
			stroke-dashoffset: -59;
		}
	}
</style>
